
import { Component, Prop, Watch } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import { generateDynamicGroupedList } from '@/helpers/GroupedListHelper';
import { LocalStorageService } from '@/services/LocalStorageService';
import { mapTranslationByKey } from '@/helpers/TranslationHelper';
import { determineWhetherNewEntityCanBeCreated, getDefaultValueForSelectedGroup } from '@/helpers/CmsShowHelper';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import User from '@/models/User';
import { Button, Select } from 'ant-design-vue';
import { CurrentEntityOptions } from '@/interfaces/components/cms/CurrentEntityOptions';
import UserRightsCopier from '@/components/views/CmsShow/UserRightsCopier.vue';

@Component({
    name: 'CmsShowActions',
    components: { UserRightsCopier, Select, SelectOption: Select.Option, Button },
})
export default class CmsShowActions extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ default: null }) private entityName!: CmsEntityTypes;
    @Prop({ default: '' }) private selectedGroup!: string;
    @Prop({ required: true }) private currentEntityData!: CurrentEntityOptions;

    private get isUserRightsCopierVisible() {
        return this.entityName === CmsEntityTypes.Users;
    }

    private get isTesting() {
        // checks if Cypress is currently running the app
        // @ts-ignore
        return window.Cypress;
    }

    private get dropdownOptions() {
        if (this.currentEntityData.groupByProperties == null) {
            return [];
        }
        const groupedList = generateDynamicGroupedList(
            this.currentEntityData.dataSource,
            this.currentEntityData.groupByProperties
        );
        return Object.keys(groupedList);
    }

    private get isAllowedToCreateNew() {
        return determineWhetherNewEntityCanBeCreated(this.entityName, this.canCreateUser, this.isSuperAdmin);
    }

    private optionTranslation(key: string) {
        if (!this.currentEntityData.shouldLabelsBeTranslated) {
            return key;
        }
        return this.$t(mapTranslationByKey(key));
    }

    private async onCreateNew() {
        if (this.entityName == null) {
            return;
        }
        if (this.entityName === CmsEntityTypes.DimensionsWizard) {
            this.$router.push({
                name: RouteNames.newProductSystemWizard,
                params: {
                    entityId: 'new',
                },
            });
            return;
        }
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: this.entityName,
                entityId: 'new',
            },
        });
    }

    private onResetUserRights() {
        User.resetUserRights();
    }

    private onSelectedGroupChange(option: string) {
        this.$emit('update:selectedGroup', option);
        LocalStorageService.save(`${this.entityName}-group`, option);
    }

    @Watch('$route', { deep: true, immediate: true })
    private async onRouteChange() {
        const defaultValue = getDefaultValueForSelectedGroup(this.entityName, this.dropdownOptions);
        this.$emit('update:selectedGroup', defaultValue);
    }
}
